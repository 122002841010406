import React from 'react'
// import { Link } from 'gatsby'
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '../components/tabs';
import Columnar from '../components/columnar'

const CountryTabs = ({content}) => {
  return (
    <div className="section-country-tabs">
      <div className="container">
        <h2 className="title">{content.title}</h2>
        <Tabs initialValue={0}>
          <TabList>
            {content.tabs.map((item, i) => {
              return (
                <>
                  <Tab name={i} key={i}>
                    <div className="image-wrapper">
                      <img src={item.image} className="image" alt={item.tabTitle}/>
                    </div>
                    <h3 className="tab-title">{item.tabTitle}</h3>
                  </Tab>
                  {/* <Tab name={i} key={i}>
                    <div>{item.tabTitle}</div>
                  </Tab> */}
                </>
              )
            })}
          </TabList>
          {content.tabs.map((item, i) => {
            return (
              <TabPanel name={i} key={i}>
                <Columnar content={item.tabsContent} />
              </TabPanel>
            )
          })}
        </Tabs>
      </div>
    </div>
  )
}

export default CountryTabs
